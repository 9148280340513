<template>
  <v-container fluid class="pt-1 pb-0">
    <base-material-card inline icon="mdi-clipboard-text" :title="$t('Manager tabele')">
      <table-management
        ref="table"
        table="view"
        app-view="table"
        edit-type="custom"
        @addData="addItem"
        @editData="editItem"
      />
      <add-form ref="addData" view-type="table" @reload="reloadData" />
    </base-material-card>
  </v-container>
</template>
<script>
import TableManagement from "../../components/containers/TableManagement"
import AddForm from "./AddForm"
export default {
  components: { AddForm, TableManagement },
  data: () => ({}),
  computed: {},
  created() {
    this.$log("createdtm ", this.$route)
  },
  methods: {
    editItem(item) {
      this.$refs.addData.editData(item)
    },
    addItem() {
      this.$refs.addData.addData()
    },
    reloadData() {
      this.$log("reloaddata")
      this.$refs.table.getRecords()
    }
  }
}
</script>
