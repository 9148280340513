<template>
  <v-dialog v-model="show" persistent scrollable>
    <v-card outlined>
      <!-- <v-system-bar color="grey lighten-2" height="32">
        <v-spacer />
        <v-btn icon small @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-system-bar> -->
      <v-card-title>
        {{ addType == "form" ? $t("Formular") : $t("Tabel") }}
        <v-spacer />

        <!-- <v-icon aria-label="Close" @click="show = false">
          mdi-close
        </v-icon> -->
        <v-icon color="red" class="mt-n2 mr-n2" large @click="show = false">
          mdi-close
        </v-icon>
      </v-card-title>
      <v-card-text class="py-0">
        <v-form ref="dataForm">
          <v-row dense>
            <v-col cols="4">
              <v-text-field
                ref="textfield"
                v-model="title"
                dense
                autofocus
                :label="$t('Nume')"
                :rules="inputRequired"
              />
            </v-col>
            <v-col cols="4">
              <v-combobox
                v-model="collection"
                dense
                :label="$t('Colecție')"
                :rules="inputRequired"
                item-text="title"
                item-value="collection"
                :return-object="false"
                :items="collections"
                @change="collectionChanged"
              />
              <!-- <s-autocomplete
                v-model="collection"
                :rules="inputRequired"
                name="sautoelseformc"
                :label="$t('collection')"
                item-text="title"
                item-value="collection"
                collection="app_schema"
              /> -->
            </v-col>

            <v-col v-if="collectionCategories()" cols="4">
              <v-select
                v-model="collectionCategory"
                dense
                clearable
                item-text="name"
                item-value="value"
                :label="$t('Categorie')"
                :items="collectionCategories()"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="4">
              <v-select
                v-model="viewDataType"
                dense
                hide-details
                :items="['data', 'html', 'marc']"
                :label="$t('Tip')"
              />
            </v-col>
            <v-col cols="4">
              <v-checkbox v-model="defaultValue" class="mt-0" dense hide-details :label="$t('Predefinit')" />
            </v-col>
            <v-col cols="4">
              <v-checkbox v-model="fullscreen" class="mt-0" dense hide-details :label="$t('Ecran complet')" />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="4">
              <v-autocomplete
                v-model="appTypes"
                dense
                hide-details
                disabled
                :label="$t('Tip aplicație')"
                item-text="name"
                item-value="value"
                :items="appTypesValues"
              />
            </v-col>
            <v-col cols="4">
              <v-autocomplete
                v-model="companies"
                dense
                hide-details
                multiple
                :label="$t('Companie')"
                item-text="name"
                item-value="_id"
                :disabled="appType != 'system'"
                :items="companiesValues"
              />
            </v-col>
            <v-col cols="4">
              <v-autocomplete
                v-model="users"
                dense
                hide-details
                multiple
                :label="$t('Utilizator')"
                item-text="username"
                item-value="_id"
                :items="usersValues"
              />
            </v-col>

            <v-col v-show="collection && viewDataType == 'html'" cols="12">
              <v-autocomplete
                v-model="htlmDictionary"
                class="custom-placeholer-color"
                chips
                deletable-chips
                dense
                color="primary"
                :label="$t('Dicționar')"
                item-text="name"
                item-value="value"
                multiple
                :items="dictionaryList"
              />
              <v-textarea v-model="htmlSource" auto-grow />
            </v-col>
            <v-col v-show="collection && viewDataType != 'html'" class="dense" cols="12">
              <v-tabs v-model="selectedTab" @change="changeTab">
                <v-tab>{{ $t("Schiță") }}</v-tab>
                <v-tab>{{ $t("Previzualizare") }}</v-tab>
                <v-tab>{{ $t("JSON") }}</v-tab>
              </v-tabs>
              <v-tabs-items v-model="selectedTab">
                <v-tab-item>
                  <v-row dense>
                    <v-col class="dense" cols="12">
                      <draggable
                        class="list-group"
                        :list="mainList"
                        :clone="cloneComponent"
                        :group="{ name: 'g1', pull: 'clone', put: false }"
                      >
                        <v-chip
                          v-for="el in mainList"
                          :key="el.id"
                          :style="el.typeObject == 'Divider' ? 'cursor:move;width:2px' : 'cursor:move;'"
                          :color="el.typeObject == 'Divider' ? 'grey darken-1' : ''"
                          label
                          class="list-group-item ml-2 mt-2"
                          :class="{ 'px-0': el.typeObject == 'Divider', 'px-2': el.typeObject != 'Divider' }"
                          @mouseenter="el.show = true"
                          @mouseleave="el.show = false"
                        >
                          <v-icon>{{ el.icon }}</v-icon>
                          <span v-if="el.show">{{ el.typeObject }}</span>
                        </v-chip>
                      </draggable>
                    </v-col>
                    <v-col class="page-card-design dense" cols="8">
                      <scream-design
                        :children="list"
                        :child="{}"
                        :selected-id="selectedId"
                        style="height:100%"
                        @selectedIdChange="
                          selectedId = $event.element.id
                          selectedItem = $event.element
                          selectedParent = $event.parent
                        "
                        @delete="getJson"
                      />
                    </v-col>
                    <v-col class="page-card-design" cols="4">
                      <v-sheet
                        v-show="selectedItem.typeObject"
                        class="pt-3"
                        style="height:100%;overflow-y: auto;overflow-x: hidden;"
                      >
                        <v-combobox
                          v-model="selectedItem.key"
                          class="custom-placeholer-color"
                          dense
                          color="primary"
                          :label="$t('Câmp')"
                          item-text="text"
                          item-value="value"
                          :placeholder="selectedItem.key"
                          :items="keysList"
                          :return-object="false"
                          @change="getJson(selectedItem, $event)"
                        >
                          <template v-slot:item="{ item }">
                            <span v-if="item.text">
                              {{ item.value + " - " + item.text }}
                            </span>
                            <span v-else-if="item.value">
                              {{ item.value }}
                            </span>
                            <span v-else></span>
                          </template>
                        </v-combobox>
                        <v-text-field v-model="selectedItem.name" dense :label="$t('Etichetă')" />
                        <v-text-field
                          v-if="selectedItem.filter !== undefined"
                          v-model="selectedItem.filter"
                          dense
                          :label="$t('Filtru')"
                        />
                        <v-autocomplete
                          v-if="selectedItem.view !== undefined"
                          v-model="selectedItem.view"
                          clearable
                          dense
                          :label="$t('Vizualizator')"
                          :items="views"
                          item-text="name"
                          item-value="_id"
                        />
                        <!-- <v-autocomplete
                          v-if="selectedItem.dictionary !== undefined"
                          v-model="selectedItem.dictionary"
                          class="custom-placeholer-color"
                          dense
                          color="primary"
                          :label="$t('dictionary')"
                          item-text="name"
                          item-value="value"
                          :items="dictionaryList"
                        /> -->
                        <s-autocomplete
                          v-if="selectedItem.dictionary !== undefined"
                          v-model="selectedItem.dictionary"
                          name="sautoelseform"
                          :label="$t('Dicționar')"
                          :dense="false"
                          item-text="name"
                          item-value="value"
                          collection="app_dictionary"
                        />
                        <v-text-field
                          v-if="selectedItem.default !== undefined"
                          v-model="selectedItem.default"
                          hide-details
                          :label="$t('Predefinit')"
                        />
                        <v-checkbox
                          v-if="selectedItem.array !== undefined"
                          v-model="selectedItem.array"
                          dense
                          class="mt-0"
                          :label="$t('Listă')"
                        />
                        <v-checkbox
                          v-if="selectedItem.embed !== undefined"
                          v-model="selectedItem.embed"
                          dense
                          class="mt-0"
                          :label="$t('Încorporat')"
                        />
                        <v-checkbox
                          v-if="selectedItem.visible !== undefined"
                          v-model="selectedItem.visible"
                          dense
                          class="mt-0"
                          :label="$t('Vizibil')"
                        />
                        <!-- showHeader: true,
                        showPagination: true,
                        showAdd: true,
                        showAction: true,
                        actions: ["edit", "delete"],
                        height: "" -->
                        <v-select
                          v-if="selectedItem.viewTypes !== undefined"
                          v-model="selectedItem.viewTypes"
                          class="custom-placeholer-color"
                          dense
                          color="primary"
                          :label="$t('Tipuri vizualizare')"
                          :items="['list', 'table', 'details', 'module']"
                          chips
                          multiple
                        />
                        <v-checkbox
                          v-if="selectedItem.showHeader !== undefined"
                          v-model="selectedItem.showHeader"
                          dense
                          class="mt-0"
                          :label="$t('Afișează cap tabel')"
                        />
                        <v-checkbox
                          v-if="selectedItem.rowExpand !== undefined"
                          v-model="selectedItem.rowExpand"
                          dense
                          class="mt-0"
                          :label="$t('Extinde rând')"
                        />
                        <v-checkbox
                          v-if="selectedItem.showLabel !== undefined"
                          v-model="selectedItem.showLabel"
                          dense
                          class="mt-0"
                          :label="$t('Afișează etichetă')"
                        />
                        <v-checkbox
                          v-if="selectedItem.showField !== undefined"
                          v-model="selectedItem.showField"
                          dense
                          class="mt-0"
                          :label="$t('Afișează câmp')"
                        />
                        <v-checkbox
                          v-if="selectedItem.showSubfield !== undefined"
                          v-model="selectedItem.showSubfield"
                          dense
                          class="mt-0"
                          :label="$t('Afișează subcâmp')"
                        />
                        <v-checkbox
                          v-if="selectedItem.dense !== undefined"
                          v-model="selectedItem.dense"
                          dense
                          class="mt-0"
                          :label="$t('Dens')"
                        />
                        <v-checkbox
                          v-if="selectedItem.marc !== undefined"
                          v-model="selectedItem.marc"
                          dense
                          class="mt-0"
                          :label="$t('Afișează etichetă')"
                        />
                        <v-checkbox
                          v-if="selectedItem.autofocus !== undefined"
                          v-model="selectedItem.autofocus"
                          dense
                          class="mt-0"
                          :label="$t('Focalizare automată')"
                        />
                        <v-checkbox
                          v-if="selectedItem.filtrable !== undefined"
                          v-model="selectedItem.filtrable"
                          dense
                          class="mt-0"
                          :label="$t('Filtrabil')"
                        />
                        <v-checkbox
                          v-if="selectedItem.sortable !== undefined"
                          v-model="selectedItem.sortable"
                          dense
                          class="mt-0"
                          :label="$t('Sortabil')"
                        />
                        <v-combobox
                          v-model="selectedItem.sortableField"
                          class="custom-placeholer-color"
                          dense
                          color="primary"
                          :label="$t('Câmp sortare implicită')"
                          item-text="text"
                          item-value="value"
                          :items="keyList"
                          :return-object="false"
                        >
                          <template #item="{ item }">
                            <span v-if="item.text">
                              {{ item.value + " - " + item.text }}
                            </span>
                            <span v-else-if="item.value">
                              {{ item.value }}
                            </span>
                            <span v-else></span>
                          </template>
                        </v-combobox>
                        <v-checkbox
                          v-if="selectedItem.editInline !== undefined"
                          v-model="selectedItem.editInline"
                          dense
                          class="mt-0"
                          :label="$t('Editare din linie')"
                        />
                        <v-checkbox
                          v-if="selectedItem.showAdd !== undefined"
                          v-model="selectedItem.showAdd"
                          dense
                          class="mt-0"
                          :label="$t('Afișează adăugare')"
                        />
                        <v-checkbox
                          v-if="selectedItem.showSheet !== undefined"
                          v-model="selectedItem.showSheet"
                          dense
                          class="mt-0"
                          :label="$t('Afișează fișă')"
                        />
                        <v-checkbox
                          v-if="selectedItem.multipleSelect !== undefined"
                          v-model="selectedItem.multipleSelect"
                          dense
                          class="mt-0"
                          :label="$t('Selectare multiplă')"
                        />
                        <v-checkbox
                          v-if="selectedItem.addPopUp !== undefined"
                          v-model="selectedItem.addPopUp"
                          dense
                          class="mt-0"
                          :label="$t('Adăugare Pop-up')"
                        />
                        <v-checkbox
                          v-if="selectedItem.showSelect !== undefined"
                          v-model="selectedItem.showSelect"
                          dense
                          class="mt-0"
                          :label="$t('Afișează selectare')"
                        />
                        <v-checkbox
                          v-if="selectedItem.showMove !== undefined"
                          v-model="selectedItem.showMove"
                          dense
                          class="mt-0"
                          :label="$t('Afișează mutare')"
                        />
                        <v-checkbox
                          v-if="selectedItem.showAction !== undefined"
                          v-model="selectedItem.showAction"
                          dense
                          class="mt-0"
                          :label="$t('Afișează acțiuni')"
                        />
                        <v-select
                          v-if="selectedItem.actions !== undefined && selectedItem.showAction"
                          v-model="selectedItem.actions"
                          class="custom-placeholer-color"
                          dense
                          color="primary"
                          :label="$t('Acțiuni')"
                          :items="['edit', 'delete', 'preview', 'select', 'inventare']"
                          chips
                          multiple
                        />
                        <v-select
                          v-if="selectedItem.hideDetails !== undefined"
                          v-model="selectedItem.hideDetails"
                          class="custom-placeholer-color"
                          dense
                          color="primary"
                          :label="$t('Ascunde detalii')"
                          :items="['auto', true, false]"
                        />
                        <v-checkbox
                          v-if="selectedItem.editPopUp !== undefined"
                          v-model="selectedItem.editPopUp"
                          dense
                          class="mt-0"
                          :label="$t('Editare Pop-up')"
                        />
                        <v-checkbox
                          v-if="selectedItem.showPagination !== undefined"
                          v-model="selectedItem.showPagination"
                          dense
                          class="mt-0"
                          :label="$t('Afișează paginare')"
                        />
                        <v-select
                          v-if="selectedItem.align !== undefined"
                          v-model="selectedItem.align"
                          class="custom-placeholer-color"
                          dense
                          color="primary"
                          :label="$t('Aliniere')"
                          :items="['', 'left', 'center', 'right']"
                        />
                        <v-text-field
                          v-if="selectedItem.width !== undefined"
                          v-model="selectedItem.width"
                          dense
                          clearable
                          :label="$t('Lungime')"
                        />
                        <v-text-field
                          v-if="selectedItem.height !== undefined"
                          v-model="selectedItem.height"
                          dense
                          clearable
                          :label="$t('Înălțime')"
                        />

                        <v-text-field
                          v-if="selectedItem.columns !== undefined"
                          v-model.number="selectedItem.columns"
                          dense
                          type="number"
                          :label="$t('Coloane')"
                        />
                        <span v-if="selectedItem.prefix !== undefined">
                          {{ (selectedItem.prefix = collection) }}
                        </span>
                        <!-- <v-row v-if="selectedItem.fields !== undefined">
                          <v-col v-for="(fld, ind) in selectedItem.fields" :key="fld" cols="12">
                            <v-autocomplete
                              :value="fld"
                              class="custom-placeholer-color"
                              dense
                              hide-details
                              color="primary"
                              :label="$t('Fields')"
                              item-text="text"
                              item-value="value"
                              deletable-chips
                              :items="keyList"
                              :return-object="false"
                              @change="selectedItem.fields[ind] = $event"
                            />
                          </v-col>
                        </v-row> -->
                        <v-row v-if="selectedItem.fields !== undefined">
                          <v-col cols="12">
                            <v-simple-table dense fixed-header class="page-card-table">
                              <template v-slot:default>
                                <thead>
                                  <tr>
                                    <th class="text-left">
                                      {{ $t("Câmpuri") }}
                                      <v-icon
                                        @click="
                                          selectedItem.fields.splice(0, 0, '')
                                          editField = true
                                          editFieldValue = ''
                                          editFieldIndex = 0
                                        "
                                      >
                                        mdi-plus
                                      </v-icon>
                                    </th>
                                    <!-- <th class="text-left"></th> -->
                                  </tr>
                                </thead>
                                <!-- <tbody> -->
                                <draggable tag="tbody" :list="selectedItem.fields">
                                  <tr
                                    v-for="(item, ind) in selectedItem.fields"
                                    :key="item"
                                    @click="$set(fieldsShow, ind, true)"
                                    @mouseenter="$set(fieldsShow, ind, true)"
                                    @mouseleave="$set(fieldsShow, ind, false)"
                                  >
                                    <td>
                                      <span>
                                        {{ fieldsArray[item] }}
                                      </span>
                                      <span v-if="fieldsShow[ind] === undefined">
                                        {{ $set(fieldsShow, ind, false) }}
                                      </span>

                                      <div v-show="fieldsShow[ind]" class="float-right">
                                        <v-icon
                                          small
                                          @click="
                                            selectedItem.fields.splice(ind + 1, 0, '')
                                            editField = true
                                            editFieldValue = ''
                                            editFieldIndex = ind + 1
                                          "
                                        >
                                          mdi-plus
                                        </v-icon>
                                        <v-icon
                                          small
                                          @click="
                                            editField = true
                                            editFieldValue = item
                                            editFieldIndex = ind
                                          "
                                        >
                                          mdi-pencil
                                        </v-icon>
                                        <v-icon small @click="selectedItem.fields.splice(ind, 1)">
                                          mdi-delete
                                        </v-icon>
                                      </div>
                                    </td>
                                    <!-- <td>
                                    </td> -->
                                  </tr>
                                </draggable>

                                <!-- </tbody> -->
                              </template>
                            </v-simple-table>
                          </v-col>
                          <!-- <v-col cols="12">
                            <v-autocomplete
                              class="custom-placeholer-color"
                              dense
                              hide-details
                              color="primary"
                              :label="$t('Fields')"
                              item-text="text"
                              item-value="value"
                              deletable-chips
                              :items="keyList"
                              :return-object="false"
                            />
                          </v-col> -->
                        </v-row>
                      </v-sheet>
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item class="page-card">
                  <data-view ref="dataView" />
                </v-tab-item>
                <v-tab-item class="page-card">
                  <pre>{{ listJson }}</pre>
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn outlined color="error" @click="show = false">
          {{ $t("Anulează") }}
        </v-btn>
        <v-btn
          v-show="recordId"
          outlined
          color="info"
          @click="
            recordId = 0
            saveData()
          "
        >
          {{ $t("Adaugă") }}
        </v-btn>
        <v-btn color="primary" @click="saveData">
          {{ $t("Salvează") }}
        </v-btn>
      </v-card-actions>
      <v-dialog v-model="editField" persistent max-width="500px">
        <v-card outlined>
          <v-card-text>
            <v-autocomplete
              v-model="editFieldValue"
              autofocus
              class="custom-placeholer-color"
              dense
              hide-details
              color="primary"
              :label="$t('Câmpuri')"
              item-text="text"
              item-value="value"
              deletable-chips
              :items="keyList"
              :return-object="false"
              @keyup.enter="
                $set(selectedItem.fields, editFieldIndex, editFieldValue)
                editField = false
              "
              @keyup.esc="editField = false"
            />
          </v-card-text>
          <v-card-actions class="py-0">
            <v-spacer />
            <v-btn outlined small @click="editField = false">{{ $t("Anulează") }}</v-btn>
            <v-btn
              color="success"
              outlined
              small
              @click="
                $set(selectedItem.fields, editFieldIndex, editFieldValue)
                editField = false
              "
            >
              {{ $t("Salvează") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "../../plugins/axios"
import ScreamDesign from "../../components/containers/ScreamDesign"
import SAutocomplete from "../../components/inputs/Autocomplete"
import DataView from "../settings/DataView"
import { EventBus } from "../../EventBus"
import draggable from "vuedraggable"
let idGlobal = 50
export default {
  components: {
    draggable,
    ScreamDesign,
    DataView,
    SAutocomplete
  },
  props: {
    viewType: {
      required: true,
      type: String
    }
  },
  data: () => ({
    editField: false,
    editFieldValue: "",
    editFieldIndex: 0,
    fieldsShow: {},
    companiesValues: [],
    usersValues: [],
    appTypesValues: [],
    companies: [],
    users: [],
    appTypes: [],
    addType: "",
    collections: [],
    title: "",
    collection: "",
    collectionCategory: "",
    defaultValue: false,
    fullscreen: true,
    views: [],
    viewDataType: "data",
    htmlSource: "",
    htlmDictionary: [],
    access: "private",
    recordId: "",
    record: {},
    etag: "",
    show: false,
    listContent: {},
    fieldsArray: {},
    keyList: [],
    usesKey: [],
    list: [],
    listJson: [],
    selectedTab: 0,
    selectedId: 0,
    selectedItem: {},
    dictionaryList: [],
    mainList: [
      { name: "", key: "", typeObject: "Tabs", show: false, icon: "mdi-animation", id: 22, children: [] },
      {
        name: "",
        key: "",
        typeObject: "Block",
        visible: true,
        show: false,
        array: false,
        icon: "mdi-square-outline",
        id: 23,
        children: []
      },
      {
        name: "",
        key: "",
        typeObject: "Horizontal Box",
        visible: true,
        show: false,
        horizontal: true,
        icon: "mdi-table-row",
        id: 24,
        children: []
      },
      {
        name: "",
        key: "",
        typeObject: "Table",
        filter: "",
        view: "",
        visible: true,
        show: false,
        icon: "mdi-table",
        id: 25,
        children: [],
        addPopUp: true,
        editPopUp: true,
        viewTypes: ["table", "details", "module"],
        category: "",
        showHeader: true,
        rowExpand: false,
        showPagination: true,
        showAdd: true,
        showSheet: true,
        multipleSelect: true,
        showAction: true,
        showMove: false,
        filtrable: true,
        sortable: true,
        sortableField: "",
        editInline: false,
        actions: ["edit", "delete", "select"],
        showSelect: false,
        height: ""
      },
      {
        name: "",
        key: "",
        typeObject: "Marc",
        visible: true,
        autofocus: false,
        show: false,
        horizontal: true,
        showHeader: true,
        showLabel: true,
        showField: true,
        showSubfield: true,
        prefix: "",
        dense: true,
        hideDetails: "auto",
        columns: 1,
        icon: "mdi-alpha-m-box",
        fields: [],
        id: 41
      },
      {
        name: "",
        key: "",
        typeObject: "Divider",
        show: false,
        icon: "",
        id: 30
      },
      {
        name: "",
        key: "",
        typeObject: "TextInput",
        align: "",
        width: "",
        visible: true,
        autofocus: false,
        marc: true,
        default: "",
        show: false,
        embed: false,
        filtrable: true,
        sortable: true,
        icon: "mdi-form-textbox",
        id: 10
      },
      {
        name: "",
        key: "",
        typeObject: "TextArea",
        width: "",
        visible: true,
        autofocus: false,
        marc: true,
        default: "",
        show: false,
        filtrable: true,
        sortable: true,
        icon: "mdi-form-textarea",
        id: 11
      },
      {
        name: "",
        key: "",
        typeObject: "ComboBox",
        width: "",
        visible: true,
        autofocus: false,
        marc: true,
        default: "",
        show: false,
        filtrable: true,
        sortable: true,
        icon: "mdi-menu-down-outline",
        dictionary: "",
        id: 12
      },
      {
        name: "",
        key: "",
        typeObject: "Autocomplete",
        width: "",
        visible: true,
        autofocus: false,
        marc: true,
        default: "",
        show: false,
        filtrable: true,
        sortable: true,
        icon: "mdi-selection-search",
        dictionary: "",
        id: 17
      },
      {
        name: "",
        key: "",
        typeObject: "Password",
        width: "",
        visible: true,
        autofocus: false,
        marc: true,
        default: "",
        show: false,
        embed: false,
        filtrable: true,
        sortable: true,
        icon: "mdi-lock",
        id: 34
      },
      {
        name: "",
        key: "",
        typeObject: "Divider",
        show: false,
        icon: "",
        id: 33
      },
      {
        name: "",
        key: "",
        typeObject: "CheckBox",
        width: "",
        visible: true,
        autofocus: false,
        marc: true,
        show: false,
        filtrable: true,
        sortable: true,
        icon: "mdi-check-box-outline",
        id: 13
      },
      {
        name: "",
        key: "",
        typeObject: "Radio",
        width: "",
        visible: true,
        autofocus: false,
        marc: true,
        default: "",
        show: false,
        filtrable: true,
        sortable: true,
        icon: "mdi-radiobox-marked",
        id: 14
      },
      {
        name: "",
        key: "",
        typeObject: "Switch",
        width: "",
        visible: true,
        autofocus: false,
        marc: true,
        show: false,
        filtrable: true,
        sortable: true,
        icon: "mdi-toggle-switch",
        id: 15
      },
      {
        name: "",
        key: "",
        typeObject: "Numeric",
        width: "",
        visible: true,
        autofocus: false,
        marc: true,
        default: "",
        show: false,
        filtrable: true,
        sortable: true,
        icon: "mdi-numeric",
        id: 18
      },
      {
        name: "",
        key: "",
        typeObject: "Divider",
        show: false,
        icon: "",
        id: 32
      },
      {
        name: "",
        key: "",
        typeObject: "Date",
        width: "",
        visible: true,
        autofocus: false,
        marc: true,
        default: "",
        show: false,
        filtrable: true,
        sortable: true,
        icon: "mdi-calendar",
        id: 19
      },
      {
        name: "",
        key: "",
        typeObject: "Time",
        width: "",
        visible: true,
        autofocus: false,
        marc: true,
        default: "",
        show: false,
        filtrable: true,
        sortable: true,
        icon: "mdi-clock-outline",
        id: 20
      },
      {
        name: "",
        key: "",
        typeObject: "DateTime",
        width: "",
        visible: true,
        autofocus: false,
        marc: true,
        default: "",
        show: false,
        filtrable: true,
        sortable: true,
        icon: "mdi-calendar-clock",
        id: 21
      },
      {
        name: "",
        key: "",
        typeObject: "Divider",
        show: false,
        icon: "",
        id: 31
      },
      { name: "", key: "", typeObject: "Button", visible: true, marc: true, show: false, icon: "mdi-card", id: 26 },
      {
        name: "",
        key: "",
        typeObject: "Image",
        visible: true,
        marc: true,
        show: false,
        height: "",
        width: "",
        icon: "mdi-camera-image",
        id: 40
      },
      {
        name: "",
        key: "",
        typeObject: "Link",
        width: "",
        visible: true,
        autofocus: false,
        marc: true,
        show: false,
        filtrable: true,
        sortable: true,
        icon: "mdi-transit-connection-variant",
        id: 27
      },
      {
        name: "",
        key: "",
        typeObject: "ColorPicker",
        width: "",
        visible: true,
        autofocus: false,
        marc: true,
        show: false,
        filtrable: true,
        sortable: true,
        icon: "mdi-palette",
        id: 16
      }
    ]
  }),
  computed: {
    inputRequired() {
      const rule = []
      rule.push(v => !!v || this.$t("required"))
      return rule
    },
    keysList() {
      return this.keyList.filter(itm => !this.usesKey.includes(itm.value))
    },
    companyId() {
      return this.$store.getters.companyId
    },
    userId() {
      return this.$store.getters.accountId
    },
    appType() {
      return this.$store.getters.appType
    },
    userappTypes() {
      return this.$store.getters.appTypes
    }
  },
  mounted() {
    let schmurl = "app_schema"
    let compurl = "app_company"
    let userurl = "app_user"
    if (this.appType !== "system") {
      schmurl = 'app_schema?where={"$or":['
      this.userappTypes.map((itm, ind) => {
        if (ind > 0) schmurl += ","
        schmurl +=
          '{"owner.app_type":"' +
          itm +
          '","owner.company":{"$exists":false}},{"owner.app_type":"' +
          itm +
          '","owner.company":"' +
          this.companyId +
          '"}'
      })
      schmurl += "]}$max_results=1000"
      // schmurl ='app_schema?where={"$or":[{"owner.app_type":"notar","owner.company":{"$exists":false}},{"owner.app_type":"notar","owner.company":"' +this.companyId +'"}]}'
      compurl = 'app_company?where={"_id":"' + this.companyId + '"}'
      userurl = 'app_user?where={"company":"' + this.companyId + '"}'
      this.companies = [this.companyId]
    } else {
      schmurl += "?max_results=1000"
    }
    axios.get('app_system_dictionary?where={"value":"app_type"}').then(response => {
      if (this.appType !== "system") {
        this.appTypesValues = response.data._items[0].content.filter(itm => this.userappTypes.includes(itm.value))
      } else {
        this.appTypesValues = response.data._items[0].content
      }
    })
    axios.get(compurl).then(response => {
      this.companiesValues = response.data._items
    })
    axios.get(userurl).then(response => {
      this.usersValues = response.data._items
    })
    axios.get(schmurl).then(response => {
      this.$log("schema reload", response)
      this.collections = response.data._items
    })
    axios.get('app_dictionary?projection={"name":1,"value":1}&max_results=1000').then(response => {
      // this.$log("schema reload", response)
      response.data._items.map(itm => (itm.value = "all." + itm.value))
      this.dictionaryList.push(...response.data._items)
    })
    axios.get('app_system_dictionary?projection={"name":1,"value":1}').then(response => {
      // this.$log("schema reload", response)
      response.data._items.map(itm => (itm.value = "system." + itm.value))
      this.dictionaryList.push(...response.data._items)
    })
  },
  methods: {
    collectionCategories() {
      //this.$log("collectionCategories ", this.collection)
      const cats = [{ name: "", value: "" }]
      const dic = "all_categorii_" + this.collection.substring(5)
      if (this.$store.getters.dictionary(dic)) {
        //this.$log("dicccccccccccccc ", this.$store.getters.dictionary(dic))
        return this.$store.getters.dictionary(dic)
      } else {
        //this.$log("axios ", "categorii_" + this.collection.substring(5))
        //this.$log("get " + 'app_dictionary?where={"value":"' + "categorii_" + this.collection.substring(5) + '"}')
        //this.getdictionary("categorii_" + this.collection.substring(5))
        axios
          .get('app_dictionary?where={"value":"' + "categorii_" + this.collection.substring(5) + '"}')
          .then(response => {
            //this.$log("res dic ", response)
            if (response.data._items[0]) {
              this.$store.commit("dictionary", { name: dic, values: response.data._items[0].content })
              return response.data._items[0].content
            }
          })
          .catch(err => {
            this.$log("errrrrrr ", err)
            return cats
          })
      }
    },
    collectionChanged(itm) {
      //this.$log("collectionChanged ", itm)
      //this.$log("collectionChanged ", this.collection)
      this.views = this.$store.getters.appView.filter(itm => itm.collection == this.collection && itm.type == "form")
      this.list = []
      this.keyList = [{ text: "", value: "" }]
      // this.collections[this.collections.findIndex(itm => itm.collection == this.collection)].content
      if (this.collections.find(itm => itm.collection === this.collection)) {
        this.appTypes = this.collections.find(itm => itm.collection === this.collection).owner.app_type
        this.generateList(this.collections.find(itm => itm.collection === this.collection).content, "")
      }
    },
    cloneComponent(prop) {
      const newprop = { ...prop }
      newprop.id = Date.now() // idGlobal++
      if (newprop.children) newprop.children = []
      return newprop
    },
    changeTab() {
      this.$log("tabbb ", this.selectedTab)
      this.getJson()
      if (this.selectedTab === 1) {
        if (this.$refs.dataView) {
          this.$refs.dataView.showData({}, this.listJson)
        } else {
          setTimeout(() => {
            this.$refs.dataView.showData({}, this.listJson)
          }, 300)
        }
      }
    },
    getJson(select, e) {
      if (e) {
        if (this.keyList.find(itm => itm.value == e)) {
          select.name = this.keyList.find(itm => itm.value == e).text
        }
      }
      this.listJson = this.generateJsonList(this.list, [])
      this.usesKey = []
      this.generateJson(this.list)
    },
    generateJson(list) {
      list.map(itm => {
        if (itm.key) this.usesKey.push(itm.key)
        if (itm.children) this.generateJson(itm.children)
      })
    },
    addData(addType) {
      this.addType = addType
      this.show = true
      this.recordId = 0
      this.selectedItem = {}
      this.title = ""
      this.collection = ""
      this.collectionCategory = ""
      this.defaultValue = false
      this.fullscreen = true
      this.viewDataType = "data"
      this.access = "private"
      this.list = []
      this.htmlSource = ""
      this.htlmDictionary = []
      this.$nextTick(() => this.$refs.dataForm.resetValidation())
      // this.$nextTick(() => {
      //   this.$log("textfieldtextfieldtextfield ", this.$refs.textfield)
      //   for (const itm in this.$refs.textfield.$props) {
      //     this.$log("prop " + itm + " -  " + typeof this.$refs.textfield.$props[itm])
      //   }
      // })
    },
    editData(e, addType) {
      this.$log("e ", e)
      this.addType = addType
      this.show = true
      this.recordId = e._id
      this.etag = e._etag
      this.selectedItem = {}
      // this.$log("etag ", this.etag)
      axios.get("app_view/" + this.recordId).then(response => {
        this.$log("schema", response)
        response.data._created = undefined
        response.data._updated = undefined
        response.data._links = undefined
        response.data._version = undefined
        response.data._latest_version = undefined
        this.record = response.data
        this.etag = response.data._etag
        this.title = response.data.name
        this.collection = response.data.collection
        this.collectionCategory = response.data.ctg
        this.defaultValue = response.data.default
        this.fullscreen = response.data.fullscreen != undefined ? response.data.fullscreen : true
        this.viewDataType = response.data.view_type ? response.data.view_type : "data"
        this.access = response.data.access
        if (this.record.owner) {
          this.companies = this.record.owner.companies
          this.users = this.record.owner.users
          this.appTypes = this.record.owner.app_type
        } else {
          this.companies = []
          this.users = []
          this.appTypes = []
        }
        // this.generateList(response.data.content, "")
        if (this.viewDataType != "html") {
          this.collectionChanged()
          this.list = []
          if (Array.isArray(response.data.content)) {
            this.list = this.generateNewList(response.data.content, [])
          }
          this.getJson()
        } else if (this.viewDataType == "html") {
          this.htmlSource = response.data.content[0].template
          this.htlmDictionary = response.data.content[0].dictionary
        }
      })
    },
    generateNewList(list, json) {
      list.map(itm => {
        //this.$log("itm ", itm)
        //const dic = { ...itm }
        const dic = { ...this.mainList.find(item => item.typeObject === itm.typeObject) }

        Object.assign(dic, itm)
        //this.$log("dic ", dic)
        dic.id = idGlobal++
        dic.icon = this.mainList.find(item => item.typeObject === itm.typeObject).icon
        if (itm.children) {
          dic.children = this.generateNewList(itm.children, [])
        }
        json.push(dic)
      })
      return json
    },

    generateList(rec, name) {
      for (const prop in rec) {
        if ((prop === "type" || prop === "schema") && rec.type && rec.schema) {
          if (prop === "type") {
            this.generateList(rec.schema, name)
          }
        } else {
          let newname = ""
          if (name) {
            newname = name + "." + prop
          } else {
            newname = prop
          }
          let lbl = newname
          const arr = lbl.split(".")
          //this.$log("arr", arr)
          if (rec[prop].meta) {
            if (rec[prop].meta.label) {
              if (arr[0] == "fields" && arr[3] == "val") {
                lbl = rec[prop].meta.label + "(" + arr[1] + (arr[2] ? arr[2] : "") + ")"
              } else {
                lbl = rec[prop].meta.label
              }
            }
          }
          if (rec[prop].schema) {
            if (rec[prop].type === "list") {
              if (this.addType === "form") {
                //this.keyList.push(newname + "[]")
                if ((arr[0] == "fields" && arr.length == 2) || arr[0] != "fields") {
                  this.keyList.push({ text: lbl, value: newname + "[]" })
                  this.fieldsArray[newname + "[]"] = lbl
                }
              } else {
                //  this.keyList.push(newname)
                if ((arr[0] == "fields" && arr.length == 2) || arr[0] != "fields") {
                  this.keyList.push({ text: lbl, value: newname })
                  this.fieldsArray[newname] = lbl
                }
              }
            }
            this.generateList(rec[prop].schema, newname)
          } else {
            //this.keyList.push(newname)
            this.fieldsArray[newname] = lbl
            //this.$log("arrlen ", arr.length)
            if ((arr[0] == "fields" && arr[3] != "ord" && arr[2] != "ord") || arr[0] != "fields") {
              this.keyList.push({ text: lbl, value: newname })
            }
          }
        }
      }
    },
    generateJsonList(list, json) {
      list.map(itm => {
        const dic = { ...itm }
        dic.id = undefined
        dic.icon = undefined
        if (itm.children) {
          dic.children = this.generateJsonList(itm.children, [])
        }
        json.push(dic)
      })
      // this.$log("generateJsonList ", json)
      return json
    },
    saveData() {
      if (this.$refs.dataForm.validate()) {
        //
        let content = []
        this.getJson()
        if (this.viewDataType == "html") {
          content = [{ type: "html", template: this.htmlSource, dictionary: this.htlmDictionary }]
        } else if (this.viewDataType != "html") {
          content = this.listJson
        }

        this.record._etag = undefined
        if (this.recordId === 0) {
          axios
            .post("app_view", {
              name: this.title,
              collection: this.collection,
              ctg: this.collectionCategory,
              content: content,
              type: this.viewType,
              default: this.defaultValue,
              fullscreen: this.fullscreen,
              view_type: this.viewDataType,
              access: this.access,
              owner: {
                user: this.users,
                company: this.companies,
                app_type: this.appTypes
              }
            })
            .then(response => {
              if (response.status === 201) {
                this.show = false
                this.$emit("reload")
              }
            })
            .catch(() => {
              EventBus.$emit("show-alert", { message: "error", timeOut: 7000 })
            })
        } else {
          axios
            .put(
              "app_view/" + this.recordId,
              {
                name: this.title,
                collection: this.collection,
                ctg: this.collectionCategory,
                content: content,
                type: this.viewType,
                default: this.defaultValue,
                fullscreen: this.fullscreen,
                view_type: this.viewDataType,
                access: this.access,
                owner: {
                  user: this.users,
                  company: this.companies,
                  app_type: this.appTypes
                }
              },
              {
                headers: {
                  "If-Match": this.etag
                }
              }
            )
            .then(response => {
              this.$log("saveData", response)
              if (response.status === 200) {
                this.show = false
                this.$emit("reload")
              }
            })
            .catch(error => {
              this.$log("error ", error)
              // this.$log("error ", error._issues.content.name)
              EventBus.$emit("show-alert", { message: "error", timeOut: 7000 })
            })
        }
      }
    }
  }
}
</script>
<style scoped>
.page-card {
  height: calc(100vh - 430px);
  overflow-y: auto;
  overflow-x: hidden;
}
.textareah {
  height: calc(100vh - 460px);
}
.page-card-design {
  height: calc(100vh - 480px);
  overflow-y: auto;
  overflow-x: hidden;
}
.page-card-table {
  height: calc(100vh - 580px);
  overflow-y: auto;
  overflow-x: hidden;
}
ul {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
}
</style>
