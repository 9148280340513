<template>
  <draggable class="dragArea list-group " tag="ul" :list="children" :group="{ name: 'g1' }" @change="dragChange">
    <li
      v-for="(el, index) in children"
      :key="el.id"
      style="cursor:move;"
      class="list-group-item  pt-2 ml-2"
      :class="[horizontal ? floatLeft : dBlock]"
    >
      <v-system-bar
        :class="[selectedId == el.id ? selectedElm : '']"
        @click="$emit('selectedIdChange', { element: el, parent: child })"
      >
        <v-icon>{{ el.icon }}</v-icon>
        {{ el.mandatory ? "*" : "" }}
        {{ el.name ? el.name : el.key ? el.key : el.typeObject }}
        <v-spacer />
        <v-icon @click.stop="deleteItem(index)">
          mdi-delete
        </v-icon>
      </v-system-bar>
      <scream-design
        v-if="el.children"
        :children="el.children"
        :child="el"
        :selected-id="selectedId"
        :horizontal="el.horizontal"
        @selectedIdChange="$emit('selectedIdChange', $event)"
        @delete="$emit('delete')"
      />
    </li>
  </draggable>
</template>
<script>
import draggable from "vuedraggable"
export default {
  name: "ScreamDesign",
  components: {
    draggable
  },
  props: {
    selectedId: {
      type: Number,
      default: 0
    },
    children: {
      required: true,
      type: Array
    },
    child: {
      required: true,
      type: Object
    },
    horizontal: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    floatLeft: "floatLeft",
    dBlock: "dBlock",
    selectedElm: "selectedElm",
    selectedElelemnt: {}
  }),
  methods: {
    deleteItem(e) {
      this.children.splice(e, 1)
      this.$emit("delete")
    },

    dragChange(e) {
      if (e.added) this.$emit("selectedIdChange", { element: e.added.element, parent: this.child })
      if (e.moved) this.$emit("selectedIdChange", { element: e.moved.element, parent: this.child })
    }
  }
}
</script>
<style scoped>
.dragArea {
  min-height: 20px;
  /* outline: 1px dashed; */
}
ul {
  list-style-type: none;
}
.selectedElm {
  border: 1px solid #888;
}
.floatLeft {
  /* display: inline-flex; */
  display: block;
}
.dBlock {
  display: block;
}
</style>
