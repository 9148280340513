import { render, staticRenderFns } from "./ScreamDesign.vue?vue&type=template&id=7a56f253&scoped=true&"
import script from "./ScreamDesign.vue?vue&type=script&lang=js&"
export * from "./ScreamDesign.vue?vue&type=script&lang=js&"
import style0 from "./ScreamDesign.vue?vue&type=style&index=0&id=7a56f253&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a56f253",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSystemBar } from 'vuetify/lib/components/VSystemBar';
installComponents(component, {VIcon,VSpacer,VSystemBar})
